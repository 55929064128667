<template>
    <div class="form_box">
        <div class="box_title">
            <span>{{ title }}</span>
            <span class="strong_text" v-if="isRequired">*</span>
        </div>
        <div class="box_content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.form_box {
    margin: 16px 0;

    .box_title {
        margin-bottom: 4px;

        .strong_text {
            color: red;
            margin-left: 5px;
        }
    }
}
</style>