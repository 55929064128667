<template>
    <div class="collapse_box" :class="customClass">
        <el-collapse :accordion="accordion">
            <el-collapse-item title="" v-for="(item, index) in collapseList" :key="index" :name="index">
                <template slot="title">
                    <div v-html="item.titleRichText"></div>
                </template>
                <div v-html="item.contentRichText"></div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
export default {
    props: {
        accordion: {
            type: Boolean,
            default: false
        },
        collapseList: {
            type: Array,
            default: () => []
        },
        customClass: {
            type: String,
            default: ''
        },
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.collapse_box {
    .el-collapse {
        border-top: 0;
    }
}
</style>